import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  Agent,
  AppSlice,
  Avatar,
  FeedbackEntity,
  ModuleName,
  OutreachCampaign,
  PageMode,
  UsageDetails,
  User,
  Video,
} from "../types"

const initialState: AppSlice = {
  openedModule: null,
  selectedVideo: null,
  selectedCampaign: null,
  selectedAgent: null,
  pageMode: PageMode.CREATE,
  selectedAvatar: null,
  avatars: [],
  avatarsLastUpdated: null,
  showStripeModal: false,
  feedbackModalState: {
    visibility: "hidden",
    title: "",
    entityId: null,
    entityType: null,
  },
  usageDetails: { current_usage: 0, usage_limit: 1000 },
  isUserAdmin: false,
  user: null,
  entitiesWithFeedback: new Set(),
}

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    updateOpenedModule: (state, action: PayloadAction<ModuleName>) => {
      state.openedModule = action.payload
    },
    resetOpenedModule: (state) => {
      state.openedModule = null
    },
    updateSelectedVideo: (state, action: PayloadAction<Video>) => {
      state.selectedVideo = action.payload
    },
    resetSelectedVideo: (state) => {
      state.selectedVideo = null
    },
    updateSelectedAgent: (state, action: PayloadAction<Agent>) => {
      state.selectedAgent = action.payload
    },
    resetSelectedAgent: (state) => {
      state.selectedAgent = null
    },
    updateSelectedCampaign: (
      state,
      action: PayloadAction<OutreachCampaign>,
    ) => {
      state.selectedCampaign = action.payload
    },
    resetSelectedCampaign: (state) => {
      state.selectedCampaign = null
    },
    updatePageMode: (state, action: PayloadAction<PageMode>) => {
      state.pageMode = action.payload
    },
    resetPageMode: (state) => {
      state.pageMode = PageMode.CREATE
    },
    updateSelectedAvatar: (state, action: PayloadAction<Avatar>) => {
      state.selectedAvatar = action.payload
    },
    resetSelectedAvatar: (state) => {
      state.selectedAvatar = null
    },
    updateAvatars: (state, action: PayloadAction<Avatar[]>) => {
      state.avatars = action.payload
      state.avatarsLastUpdated = new Date()
    },
    addAvatar: (state, action: PayloadAction<Avatar>) => {
      state.avatars.unshift(action.payload)
    },
    deleteAvatar: (state, action: PayloadAction<Avatar>) => {
      state.avatars = state.avatars.filter(
        (avatar) => avatar.id !== action.payload.id,
      )
    },
    updateAvatar: (state, action: PayloadAction<Avatar>) => {
      state.avatars = state.avatars.map((avatar) =>
        avatar.id === action.payload.id ? action.payload : avatar,
      )
    },
    updateAvatarsLastUpdated: (state, action: PayloadAction<Date>) => {
      state.avatarsLastUpdated = action.payload
    },
    updateShowStripeModal: (state, action: PayloadAction<boolean>) => {
      state.showStripeModal = action.payload
    },
    updateUsageDetails: (state, action: PayloadAction<UsageDetails>) => {
      state.usageDetails = action.payload
    },
    resetUsageDetails: (state) => {
      state.usageDetails = { current_usage: 0, usage_limit: 1000 }
    },
    updateIsUserAdmin: (state, action: PayloadAction<boolean>) => {
      state.isUserAdmin = action.payload
    },
    updateUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload
    },
    updateFeedbackModalState: (
      state,
      action: PayloadAction<AppSlice["feedbackModalState"]>,
    ) => {
      state.feedbackModalState = action.payload
    },
    updateEntitiesWithFeedback: (
      state,
      action: PayloadAction<Set<FeedbackEntity>>,
    ) => {
      state.entitiesWithFeedback = action.payload
    },
    resetState: () => initialState,
  },
})

export const AppActions = appSlice.actions
export default appSlice.reducer
