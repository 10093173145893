export enum Endpoints {
  avatar = "avatar",
  template = "template",
  campaign = "campaign",
  video = "video",
  lead_list = "lead_list",
  creator = "creator",
  create_checkout_session = "create-checkout-session",
  dataset = "dataset",
  agent = "agent",
  knowledge = "knowledge",
  current_usage = "current_usage",
  calls = "calls",
  feedback = "feedback",
}

export enum SecondPathParam {
  stripe_session = "stripe-session",
  stripe_portal_url = "stripe-portal-url",
  usage = "usage",
  generate = "generate",
  load = "load",
  load_preview = "load_preview",
  generate_videos = "generate_videos",
  generate_text = "generate_text",
  download_sample = "download_sample",
  load_preview_image = "load_preview_image",
  upload = "upload",
  process = "process",
  download_all_videos = "download_all_videos",
  generate_csv = "generate_csv",
  calls = "calls",
  messages = "messages",
  products = "products",
  check_agent_field_safety = "check_agent_field_safety",
}

export enum Method {
  POST = "POST",
  GET = "GET",
  PUT = "PUT",
  DELETE = "DELETE",
}

export enum ReturnTypes {
  json = "json",
  blob = "blob",
}

export enum ApiState {
  pending = "pending",
  processing = "processing",
  error = "error",
  ready = "ready",
}

export type QueryParams = {
  last_id: string
  limit: number
}
