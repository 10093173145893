import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { NonPersistedSlice } from "../types"

const initialState: NonPersistedSlice = {
  showSubscriptionsModalOnAppLoad: false,
}

const nonPersistedSlice = createSlice({
  name: "nonPersisted",
  initialState,
  reducers: {
    updateShowSubscriptionsModalOnAppLoad: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.showSubscriptionsModalOnAppLoad = action.payload
    },
    resetShowSubscriptionsModalOnAppLoad: (state) => {
      state.showSubscriptionsModalOnAppLoad = false
    },
    resetState: () => initialState,
  },
})

export const NonPersistedActions = nonPersistedSlice.actions
export default nonPersistedSlice.reducer
