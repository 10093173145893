import { Location } from "react-router-dom"
import { getScreenModeFromScreen, ScreenModes } from "./UI"

export enum Screen {
  outreachCampaigns = "outreachCampaigns",
  avatars = "avatars",
  myAgents = "myAgents",
  knowledgeBase = "knowledgeBase",
  conversations = "conversations",
  analytics = "analytics",
  videos = "videos",
  settings = "settings",
  auth = "auth",
  enterPassword = "enterPassword",
}

export enum AuthScreen {
  manageAccount = "manageAccount",
  resetPassword = "resetPassword",
  newPassword = "newPassword",
  verifyEmail = "verifyEmail",
}

export enum SettingsScreen {
  subscription = "subscription",
}

export enum OutreachCampaignScreen {
  selectCampaignAvatar = "selectCampaignAvatar",
  preview = "preview",
  createCampaignMessage = "createCampaignMessage",
  generateCampaignVideos = "generateCampaignVideos",
  campaignVideosList = "campaignVideosList",
  campaignVideosDownload = "campaignVideosDownload",
  finalResult = "finalResult",
}

export enum AvatarScreen {
  selectAvatar = "selectAvatar",
  createAvatar = "createAvatar",
  generateAvatar = "generateAvatar",
}

export enum VideoScreen {
  selectVideoAvatar = "selectVideoAvatar",
  createVideoMessage = "createVideoMessage",
  finalResult = "finalResult",
  generateVideos = "generateVideos",
  videoList = "videoList",
}

export enum AgentScreen {
  selectAvatar = "selectAvatar",
  agentsInfo = "agentsInfo",
  trainAgent = "trainAgent",
  finalResult = "finalResult",
  agentDetails = "agentDetails",
  conversationDetails = "conversationDetails",
}

export enum CreationModes {
  campaign = "campaign",
  video = "video",
}

export enum PageMode {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
}

export enum ConversationScreen {
  conversationDetails = "conversationDetails",
}

export const getStepNumberFromScreen = (
  screen: OutreachCampaignScreen | AvatarScreen | VideoScreen,
) => {
  switch (screen) {
    case OutreachCampaignScreen.selectCampaignAvatar:
      return 1
    case OutreachCampaignScreen.createCampaignMessage:
      return 2
    case OutreachCampaignScreen.preview:
      return 3
    case OutreachCampaignScreen.campaignVideosList:
      return 4
    case OutreachCampaignScreen.generateCampaignVideos:
      return 4
    case OutreachCampaignScreen.campaignVideosDownload:
      return 4
    case AvatarScreen.createAvatar:
      return 1
    case VideoScreen.selectVideoAvatar:
      return 1
    case VideoScreen.createVideoMessage:
      return 2
    case VideoScreen.generateVideos:
      return 3
    case VideoScreen.videoList:
      return 3
  }
}

export const getBoldTextFromScreen = (
  screen: OutreachCampaignScreen | AvatarScreen | VideoScreen,
) => {
  switch (screen) {
    case OutreachCampaignScreen.selectCampaignAvatar:
    case AvatarScreen.selectAvatar:
    case VideoScreen.selectVideoAvatar:
      return "Select avatar"
    case OutreachCampaignScreen.createCampaignMessage:
      return "Define Message & Upload Leads list"
    case OutreachCampaignScreen.preview:
      return "Preview"
    case OutreachCampaignScreen.generateCampaignVideos:
    case VideoScreen.generateVideos:
    case AvatarScreen.generateAvatar:
      return "Almost there!"
    case OutreachCampaignScreen.campaignVideosList:
    case OutreachCampaignScreen.campaignVideosDownload:
      return "Final result"
    case AvatarScreen.createAvatar:
      return "Upload Video"
    case VideoScreen.createVideoMessage:
      return "Define message"
  }
}

export const getNrOfStepsFromScreenMode = (screenMode: ScreenModes): number => {
  switch (screenMode) {
    case ScreenModes.outreachCampaign:
      return 4
    case ScreenModes.avatar:
      return 2
    case ScreenModes.video:
      return 3
  }
}

export const getFirstTextFromScreenMode = (
  screen: OutreachCampaignScreen | AvatarScreen | VideoScreen,
): string => {
  if (screen === OutreachCampaignScreen.campaignVideosDownload) {
    return "Download Videos"
  }
  switch (getScreenModeFromScreen(screen)) {
    case ScreenModes.outreachCampaign:
      return "New Campaign"
    case ScreenModes.avatar:
      return "Create Avatar"
    case ScreenModes.video:
      return "New Video"
  }
}

export const getDescriptionTextFromScreen = (
  screen: OutreachCampaignScreen | AvatarScreen | VideoScreen,
) => {
  switch (screen) {
    case OutreachCampaignScreen.selectCampaignAvatar:
    case AvatarScreen.selectAvatar:
    case VideoScreen.selectVideoAvatar:
      return "Please select from the available avatars or create your own"
    case OutreachCampaignScreen.createCampaignMessage:
      return "Please define your message and upload your leads list as a .csv file."
    case VideoScreen.createVideoMessage:
      return "Please define your message."
    case OutreachCampaignScreen.preview:
      return "Here you can preview the final message, how it will appear on the video."
    case OutreachCampaignScreen.generateCampaignVideos:
    case VideoScreen.generateVideos:
      return "Please proceed to the payment to see & download the rest of the videos"
    case AvatarScreen.generateAvatar:
      return "Please proceed to the payment to see & download your Digital Avatar."
    case AvatarScreen.createAvatar:
      return "Please follow the instructions to create your own avatar"
    case OutreachCampaignScreen.campaignVideosList:
    case VideoScreen.videoList:
    case OutreachCampaignScreen.campaignVideosDownload:
      return "Video generation is in progress. Estimated processing time: 2h."
  }
}

export const getScreenFromLocation = (
  location: Location,
): OutreachCampaignScreen | AvatarScreen | VideoScreen => {
  const splitLocation = location.pathname.split("/")
  return splitLocation[splitLocation.length - 1] as
    | OutreachCampaignScreen
    | AvatarScreen
    | VideoScreen
}
