import { Close } from "@mui/icons-material"
import {
  Box,
  CircularProgress,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../redux"
import { AppActions } from "../../redux/appSlice"
import { getStripeProducts } from "../../services"
import { StripeProduct } from "../../types"
import { ProductBox } from "../productBox"

interface StripePaymentModalProps {}

function useStripeProducts() {
  const [stripeProducts, setStripeProducts] = useState<
    Record<string, StripeProduct>
  >({})
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)

  const fetchProducts = useCallback(async () => {
    try {
      setLoading(true)
      setError(null)
      const products = await getStripeProducts()
      setStripeProducts(products)
    } catch (error) {
      console.error("Stripe Products Error:", {
        message: error?.message,
        stack: error?.stack,
        error: JSON.stringify(error, null, 2),
      })
      setError(error?.message || "Failed to fetch products")
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchProducts()
  }, [fetchProducts])

  return { stripeProducts, loading, error, refetch: fetchProducts }
}

export function StripePaymentModal({}: StripePaymentModalProps) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const isModalShown = useAppSelector((state) => state.app.showStripeModal)
  const dispatch = useAppDispatch()

  const { stripeProducts, loading, error, refetch } = useStripeProducts()

  const Products = useMemo(() => {
    if (Object.keys(stripeProducts).length === 0) return []

    return Object.values(stripeProducts).sort((a, b) => {
      if (!a.prices?.[0].recurring && b.prices?.[0].recurring) return 1
      if (a.prices?.[0].recurring && !b.prices?.[0].recurring) return -1
      return (a.prices?.[0].unit_amount ?? 0) - (b.prices?.[0].unit_amount ?? 0)
    })
  }, [stripeProducts])

  const handleCloseModal = () => {
    dispatch(AppActions.updateShowStripeModal(false))
  }

  if (!isModalShown) return null

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 100,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(78, 64, 90, 0.40)",
      }}
    >
      <Box
        sx={{
          width: isMobile ? "95%" : "90%",
          height: isMobile ? "80%" : "40rem",
          maxHeight: "40rem",
          backgroundColor: "white",
          borderRadius: "0.25rem",
          position: "relative",
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            margin: "1rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <Typography variant="body1" align="center">
            Choose a plan that's right for you
          </Typography>
          {error && (
            <Typography
              variant="body2"
              color="error"
              sx={{ mt: 1, cursor: "pointer" }}
              onClick={refetch}
            >
              {error} Click to retry.
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            mb: "1rem",
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            height: isMobile ? "auto" : "90%",
            padding: "1rem",
          }}
        >
          {loading ? (
            <Box sx={{ width: "100%", textAlign: "center", py: 4 }}>
              <CircularProgress size={24} />
              <Typography variant="body1" sx={{ mt: 2 }}>
                Loading products...
              </Typography>
            </Box>
          ) : Products.length === 0 && !error ? (
            <Typography variant="body1" align="center" sx={{ width: "100%" }}>
              No products available.
            </Typography>
          ) : (
            !error &&
            Products.map((product, index) => (
              <ProductBox
                key={index}
                product={product}
                sx={{
                  width: isMobile ? "100%" : "30%",
                  marginBottom: isMobile ? "1rem" : 0,
                  marginRight: isMobile ? 0 : "1rem",
                }}
              />
            ))
          )}
        </Box>
        <IconButton
          onClick={handleCloseModal}
          sx={{ position: "absolute", top: "0.5rem", right: "0.5rem" }}
        >
          <Close />
        </IconButton>
      </Box>
    </Box>
  )
}
