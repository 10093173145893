import { combineReducers, createStore } from "@reduxjs/toolkit"
import { useDispatch, useSelector } from "react-redux"
import { persistReducer, persistStore } from "redux-persist"
import storage from "redux-persist/lib/storage"
import appReducer from "./appSlice"
import nonPersistedReducer from "./nonPersistedSlice"

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["app"],
}

const rootReducer = combineReducers({
  app: appReducer,
  nonPersisted: nonPersistedReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(persistedReducer)
const persistor = persistStore(store)

type RootState = ReturnType<typeof store.getState>
type AppDispatch = typeof store.dispatch

const useAppDispatch = useDispatch.withTypes<AppDispatch>()
const useAppSelector = useSelector.withTypes<RootState>()

const clearPersistedState = () => {
  persistor.purge()
}

export { clearPersistedState, persistor, store, useAppDispatch, useAppSelector }
