import { Box, Typography } from "@mui/material"
import React from "react"
import { useAppDispatch, useAppSelector } from "../../redux"
import { AppActions } from "../../redux/appSlice"
import { Colors } from "../../types"

interface CreditsCalculationProps {}

export function CreditsCalculation({}: CreditsCalculationProps) {
  const usageDetails = useAppSelector((state) => state.app.usageDetails)

  let usedCredits = 0
  let totalCredits = 0
  if (usageDetails) {
    usedCredits = usageDetails.current_usage / 1000
    totalCredits = usageDetails.usage_limit / 1000
  }
  const availableCredits =
    totalCredits > usedCredits ? totalCredits - usedCredits : 0

  const dispatch = useAppDispatch()

  const handleUpgrade = () => {
    dispatch(AppActions.updateShowStripeModal(true))
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "0.5rem",
        backgroundColor: Colors.purple100,
        borderRadius: "0.5rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            display: "flex",
            flexDirection: "row",
            color: Colors.almostBlack,
            fontSize: "0.875rem",
          }}
          variant="body2"
        >
          Available Credits: {availableCredits.toFixed(0)}/
          <span style={{ color: Colors.grey900 }}>
            {totalCredits.toFixed(0)}
          </span>
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: Colors.purple900,
            fontWeight: "bold",
            cursor: "pointer",
            fontSize: "0.875rem",
          }}
          onClick={handleUpgrade}
        >
          Upgrade
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "flex-end",
          mt: "0.25rem",
        }}
      >
        <Typography
          sx={{ color: Colors.grey900, fontSize: "0.875rem" }}
          variant="body2"
        >
          1 Credit = 1 min video
        </Typography>
      </Box>
    </Box>
  )
}
